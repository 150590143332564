// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-complaints-tsx": () => import("./../../../src/pages/complaints.tsx" /* webpackChunkName: "component---src-pages-complaints-tsx" */),
  "component---src-pages-consumer-commerce-tsx": () => import("./../../../src/pages/consumer-commerce.tsx" /* webpackChunkName: "component---src-pages-consumer-commerce-tsx" */),
  "component---src-pages-consumer-info-tsx": () => import("./../../../src/pages/consumer-info.tsx" /* webpackChunkName: "component---src-pages-consumer-info-tsx" */),
  "component---src-pages-consumer-payments-tsx": () => import("./../../../src/pages/consumer-payments.tsx" /* webpackChunkName: "component---src-pages-consumer-payments-tsx" */),
  "component---src-pages-consumer-tariffs-tsx": () => import("./../../../src/pages/consumer-tariffs.tsx" /* webpackChunkName: "component---src-pages-consumer-tariffs-tsx" */),
  "component---src-pages-contacts-distribution-systems-tsx": () => import("./../../../src/pages/contacts-distribution-systems.tsx" /* webpackChunkName: "component---src-pages-contacts-distribution-systems-tsx" */),
  "component---src-pages-contacts-tsx": () => import("./../../../src/pages/contacts.tsx" /* webpackChunkName: "component---src-pages-contacts-tsx" */),
  "component---src-pages-corporate-commerce-tsx": () => import("./../../../src/pages/corporate-commerce.tsx" /* webpackChunkName: "component---src-pages-corporate-commerce-tsx" */),
  "component---src-pages-corporate-info-tsx": () => import("./../../../src/pages/corporate-info.tsx" /* webpackChunkName: "component---src-pages-corporate-info-tsx" */),
  "component---src-pages-corporate-payments-tsx": () => import("./../../../src/pages/corporate-payments.tsx" /* webpackChunkName: "component---src-pages-corporate-payments-tsx" */),
  "component---src-pages-corporate-tariffs-tsx": () => import("./../../../src/pages/corporate-tariffs.tsx" /* webpackChunkName: "component---src-pages-corporate-tariffs-tsx" */),
  "component---src-pages-electrical-safety-tsx": () => import("./../../../src/pages/electrical-safety.tsx" /* webpackChunkName: "component---src-pages-electrical-safety-tsx" */),
  "component---src-pages-free-price-tsx": () => import("./../../../src/pages/free-price.tsx" /* webpackChunkName: "component---src-pages-free-price-tsx" */),
  "component---src-pages-get-contract-tsx": () => import("./../../../src/pages/get-contract.tsx" /* webpackChunkName: "component---src-pages-get-contract-tsx" */),
  "component---src-pages-help-center-tsx": () => import("./../../../src/pages/help-center.tsx" /* webpackChunkName: "component---src-pages-help-center-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-online-treament-tsx": () => import("./../../../src/pages/online-treament.tsx" /* webpackChunkName: "component---src-pages-online-treament-tsx" */),
  "component---src-pages-press-center-tsx": () => import("./../../../src/pages/press-center.tsx" /* webpackChunkName: "component---src-pages-press-center-tsx" */),
  "component---src-pages-submit-info-tsx": () => import("./../../../src/pages/submit-info.tsx" /* webpackChunkName: "component---src-pages-submit-info-tsx" */),
  "component---src-templates-news-template-tsx": () => import("./../../../src/templates/news-template.tsx" /* webpackChunkName: "component---src-templates-news-template-tsx" */),
  "component---src-templates-open-news-template-tsx": () => import("./../../../src/templates/open-news-template.tsx" /* webpackChunkName: "component---src-templates-open-news-template-tsx" */),
  "component---src-templates-page-template-tsx": () => import("./../../../src/templates/page-template.tsx" /* webpackChunkName: "component---src-templates-page-template-tsx" */)
}

