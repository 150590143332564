import React, {createContext, useReducer, useEffect} from 'react';

interface IState {
  theme: string;
  fontSize: string;
  isDesktop: boolean;
}

const initialState: IState = {
  theme: 'regular',
  fontSize: 'regular',
  isDesktop: false,
};

const reducer = (state: IState, action: {type: string}) => {
  switch (action.type) {
    case 'SET_REGULAR_THEME':
      localStorage.setItem('theme', 'regular');
      localStorage.setItem('fontSize', 'regular');
      return {
        ...state,
        theme: 'regular',
        fontSize: 'regular',
      };
    case 'SET_BLACK_ON_WHITE_THEME':
      localStorage.setItem('theme', 'blackOnWhite');
      return {
        ...state,
        theme: 'blackOnWhite',
      };
    case 'SET_WHITE_ON_BLACK_THEME':
      localStorage.setItem('theme', 'whiteOnBlack');
      return {
        ...state,
        theme: 'whiteOnBlack',
      };

    case 'SET_REGULAR_FONT_SIZE':
      localStorage.setItem('fontSize', 'regular');
      return {
        ...state,
        fontSize: 'regular',
      };
    case 'SET_BIG_FONT_SIZE':
      localStorage.setItem('fontSize', 'big');
      return {
        ...state,
        fontSize: 'big',
      };
    case 'SET_HUGE_FONT_SIZE':
      localStorage.setItem('fontSize', 'huge');
      return {
        ...state,
        fontSize: 'huge',
      };

    case 'SET_IS_DESKTOP':
      return {
        ...state,
        isDesktop: window.innerWidth > 650,
      };

    default:
      return state;
  }
};

export const GlobalStateContext = createContext(initialState);
export const GlobalDispatchContext = createContext({});

const GlobalThemeContextProvider: React.FC = ({children}) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const handleWindowResize = () => dispatch({type: 'SET_IS_DESKTOP'});
    window.addEventListener('resize', handleWindowResize);

    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  useEffect(() => {
    if (localStorage.getItem('theme') !== 'regular') {
      localStorage.getItem('theme') === 'blackOnWhite' && dispatch({type: 'SET_BLACK_ON_WHITE_THEME'});
      localStorage.getItem('theme') === 'whiteOnBlack' && dispatch({type: 'SET_WHITE_ON_BLACK_THEME'});
      localStorage.getItem('fontSize') === 'big' && dispatch({type: 'SET_BIG_FONT_SIZE'});
      localStorage.getItem('fontSize') === 'huge' && dispatch({type: 'SET_HUGE_FONT_SIZE'});
    }
    if (typeof window !== 'undefined') {
      dispatch({type: 'SET_IS_DESKTOP'});
    }
  }, []);

  return (
    <GlobalStateContext.Provider value={state}>
      <GlobalDispatchContext.Provider value={dispatch}>{children}</GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  );
};

export default GlobalThemeContextProvider;
